import { useEffect, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import { Typography } from '../../MaterialUiComponents';
import securityApi from '../../api/security';
import { SmallPackageInformation } from '../../components';
import { AircraftInHangar } from '../../types/security';

function HangarOverview() {
  const [hangarOverview, setHangarOverview] = useState(Array<AircraftInHangar>());

  useEffect(() => {
    securityApi.AircraftInHangar().then(res => {
      setHangarOverview(res.data);
    });
  }, []);

  return (
    <Grid  >
      <Typography variant={'h1'} align={'center'} text={"Hangar Overview"} />
      <Grid container direction="row" justifyContent="center" marginTop={2}>
        <Grid md={6} xs={12} item direction="column" >
          {hangarOverview.map((row, idx) => (
            <Grid marginBottom={idx > 0 ? 2 : 0}  >
              {idx % 2 === 0 &&
                <Paper style={{ margin: '2px' }}>
                  <SmallPackageInformation packageInformation={row} />
                </Paper>
              }
            </Grid>
          ))
          }
        </Grid>
        <Grid md={6} xs={12} item direction="column" >
          {hangarOverview.map((row, idx) => (
            <Grid marginBottom={idx > 0 ? 2 : 0}>
              {idx % 2 !== 0 &&
                <Paper style={{ margin: '2px' }}>
                  <SmallPackageInformation packageInformation={row} />
                </Paper>
              }
            </Grid>
          ))
          }
        </Grid>
      </Grid>
    </Grid>
  );
};
export default HangarOverview;