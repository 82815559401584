import { authProvider } from "../config/authProvider"
export async function getToken(): Promise<string> {
    const token = localStorage.getItem("token") || "";
    const decode = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('utf8'));
    if (decode.exp * 1000 < new Date().getTime()) {
        try {
            let authResult = await authProvider.handleRedirectPromise();
            let accountObj;
            if (authResult) {
                localStorage.setItem("token", authResult.accessToken);
                accountObj = authResult.account;
            } else {
                accountObj = authProvider.getAllAccounts()[0];
            }
            if (accountObj && authResult) { }
            else if (accountObj) {
                try {
                    authResult = await authProvider.acquireTokenSilent({
                        account: authProvider.getAllAccounts()[0],
                        scopes: ["user.read"]
                    })
                } catch (err) {
                    await authProvider.acquireTokenRedirect({ scopes: ["user.read"] });
                }
            }
            else {
                await authProvider.loginRedirect({ scopes: ["user.read"] })
            }
            if (authResult && authResult.accessToken) {
                localStorage.setItem("token", authResult.accessToken);
            }
            return authResult?.accessToken || "";
        } catch (err) {
            console.error(err)
            return "";
        }
    } else {
        return token;
    }
}