import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import { Grid, TableRow, TableCell, Button, IconButton, Badge, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import { Link, Visibility, ShoppingCart } from '@mui/icons-material';
import MobiscrollCalendar from '../../components/Calendar/MobiscrollCalendar';
import { MbscCalendarEvent } from '@mobiscroll/react';
import lineApi from '../../api/line';
import { Typography, Table, Dialog, LinearProgress, Tooltip, SelectMultiple } from '../../MaterialUiComponents';
import { MxiTask } from '../../types/tasks';
import { redirectToMxi } from '../../config/mxiProvider';
import crewPlanApi from '../../api/crewPlan';
import PartDetails from '../../components/Parts/PartDetails';
import { EmployeeSearch, Collection } from '../../components';
import { formatFloatNumberToHoursMinString } from '../../utils/dateUtils';
import { makeStyles } from '@mui/styles';
import { EventInfo } from '../../types/mobiScroll';
import { hangarPlanLineOptions } from '../../properties/lineProperties';

const statusOptions = [
    { id: 0, name: "COMPLETE" },
    { id: 1, name: "ACTV" },
    { id: 2, name: "COMMIT" },
    { id: 3, name: "IN WORK" },
];

const useStyles = makeStyles({
    //Hides the all day element in the calendar
    hiddenMobiscrollElement: {
        '& .mbsc-schedule-event-range.mbsc-ios': {
            display: 'none !important',
        },
    },
});

const defaultHangarFilters = [hangarPlanLineOptions.kefHangarBay3, hangarPlanLineOptions.kefHangarBay4, hangarPlanLineOptions.kefHangarBay5];
const defaultStatusFilters = [1, 2, 3];

const storedHangars = localStorage.getItem('hangarFilters');
const storedStatus = localStorage.getItem('statusFilters');

if (storedStatus === null || storedStatus === undefined) {
    localStorage.setItem("statusFilters", JSON.stringify(defaultStatusFilters));
}

if (storedHangars === null || storedHangars === undefined) {
    localStorage.setItem("hangarFilters", JSON.stringify(defaultHangarFilters));
}

const getHangarFiltersFromLocalStorage = (): string[] => {
    const storedFilters = localStorage.getItem("hangarFilters");
    if (storedFilters) {
        return JSON.parse(storedFilters);
    } else {
        return defaultHangarFilters;
    }
};

const getStatusFiltersFromLocalStorage = (): number[] => {
    const storedFilters = localStorage.getItem("statusFilters");
    if (storedFilters) {
        return JSON.parse(storedFilters);
    } else {
        return defaultStatusFilters;
    }
};


function HangarPlan() {
    const [calendarTasks, setCalendarTasks] = useState<EventInfo[]>([]);
    const [loading, setLoading] = useState(false);

    const [selectedHangars, setSelectedHangars] = useState<string[]>(() => {
        return getHangarFiltersFromLocalStorage();
    });

    const [selectedStatusOptions, setSelectedStatusOptions] = useState<number[]>(() => {
        return getStatusFiltersFromLocalStorage();
    });

    const [dialogOpen, setDialogOpen] = useState(false);
    const [partDialogOpen, setPartDialogOpen] = useState(false);
    const [LabourDialogOpen, setLabourDialogOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState<MxiTask>();
    const [selectedEvent, setSeletedEvent] = useState<MbscCalendarEvent>();
    const [tasksInWorkpackage, setTasksInWorkpackage] = useState(Array<MxiTask>);
    const store = useSelector((state: RootState) => state);
    const isHangarPlanAdmin = store.user.me.user_clearence_groups.includes("Hangar Plan Edit");
    const [fromDate,] = useState<Date>(new Date());
    const [toDate,] = useState<Date>(new Date());
    const classes = useStyles();

    useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        fromDate.setDate(fromDate.getDate() - 90);
        toDate.setDate(toDate.getDate() + 90);
        const tempSelectedStatus: string[] = selectedStatusOptions.map(el => statusOptions[el].name);
        setLoading(true);
        lineApi.GetLinePacketsOverview(fromDate, toDate, tempSelectedStatus, "", selectedHangars, false,false, false).then(res => {
            setLoading(false);
            const data = res.data;
            const tasks: EventInfo[] = [];
            data.forEach(packet => {
                const startDate = new Date(packet.scheduled_start_date);
                const endDate = new Date(packet.scheduled_end_date);
                const durationInMillis = endDate.getTime() - startDate.getTime();
                const durationInHours = durationInMillis / (1000 * 60 * 60); // Convert milliseconds to hours
                if (durationInHours < 4) {
                    endDate.setHours(endDate.getHours() + 4);
                }
                tasks.push({
                    "start": startDate,
                    "end": endDate,
                    "id": packet.wo_barcode,
                    "title": packet.tail_number + " " + packet.wo_name,
                    "resource": packet.wo_location,
                    "color": ReturnStatusColor(packet.wo_status, undefined),
                    "allDay": true,
                    "wo_status": packet.wo_status,
                    "parts_amounts": packet.basket,
                    "parts_available": packet.avail_basket,
                    "start_date_text": packet.scheduled_start_date_text,
                    "end_date_text": packet.scheduled_end_date_text,
                    "assigned_employees": packet.assigned_employees,
                    "mt_man_hours": packet.man_hours,
                    "mxi_man_hours": packet.mxi_man_hours,
                    "wo_includes_clean_task": packet.wo_includes_clean_task,
                });
            });
            setCalendarTasks(tasks);
        });
    }
    const ReturnStatusColor = (status: string, unassigned: boolean | undefined) => {

        if (unassigned === true) {
            return "#FF0000"
        }
        switch (status) {
            case ("COMPLETE"):
                return "#497523";
            case ("ACTV"):
                return "#F5B53F";
            case ("PAUSE"):
                return "";
            case ("COMMIT"):
                return "#E4B7B7";
            default:
                return "#506E9F";
        }
    }

    const getStatusBorderProperties = (status: string) => {
        if (status === "PAUSE") {
            return {
                borderWidth: "2px",
                borderStyle: "solid",
                borderColor: "black",
            };
        }

        return {};
    };

    const onEventClicked = (e: any) => {
        refreshWorkpackage(e.event.id, e.event);
    }
    const refreshWorkpackage = (woBarcode: string, tempSelectedEvent: MbscCalendarEvent) => {
        crewPlanApi.GetTasksByWorkpackageBarcode(woBarcode).then(res => {
            if (res.status === true) {
                setTasksInWorkpackage(res.data);
                setDialogOpen(true);
                setSeletedEvent(tempSelectedEvent);
                if (selectedTask) {
                    setSelectedTask(res.data.find(s => s.barcode === selectedTask.barcode));
                }
            }
        });
    }
    const onLabourColumnClicked = (row: MxiTask) => {
        if (isHangarPlanAdmin) {
            setLabourDialogOpen(true); setSelectedTask(row);
        }
    }
    const generateTableBody = (row: MxiTask) => {
        return (
            <TableRow>
                <TableCell>{row.mxi_task_id}</TableCell>
                <TableCell >
                    <Grid container>
                        <IconButton onClick={() => redirectToMxi(row.barcode)} style={{ backgroundColor: ReturnStatusColor(row.status, row.unassigned), borderRadius: "10px", ...getStatusBorderProperties(row.status) }}><Link color="primary" /></IconButton>
                        {row.part_amounts > 0 && <Grid style={{ margin: 'auto' }} onClick={() => { setPartDialogOpen(true); setSelectedTask(row); }}>
                            <Tooltip title={`${row.part_amounts} parts`} type='primary' placement="top-start">
                                <Badge badgeContent={row.part_amounts} color="primary" >
                                    <ShoppingCart style={{ cursor: 'pointer', margin: 'auto' }} color={row.part_not_available ? 'error' : 'primary'} />
                                </Badge>
                            </Tooltip>
                        </Grid>
                        }
                        {row.need_cert && <Grid style={{ margin: 'auto' }}> <Visibility /></Grid>}
                    </Grid>
                </TableCell>
                <TableCell style={{ width: "30%", textDecoration: row.unassigned === true ? "line-through" : undefined }}>{row.task_name}</TableCell>
                <TableCell>{row.task_start_time}</TableCell>
                <TableCell>{row.task_stop_time}</TableCell>
                <TableCell>{row.action_items_counter}</TableCell>
                <TableCell onClick={() => { onLabourColumnClicked(row) }}>
                    <List>
                        {row.assigned_employees.map(emp => (
                            <ListItem>
                                <ListItemAvatar >
                                    <Avatar
                                        style={{ height: "20px", width: "20px" }}
                                        src={emp.employee_image} />
                                </ListItemAvatar>
                                <ListItemText>{emp.employee_name}</ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </TableCell>
            </TableRow>
        )
    }
    const onSelectedEmployee = (employeeId: number) => {
        crewPlanApi.ChangeLabour(selectedTask!.barcode, employeeId, selectedTask!.wo_barcode).then(res => {
            refreshWorkpackage(selectedEvent!.id!.toString(), selectedEvent!);
        })
    }
    return (
        <Grid className={classes.hiddenMobiscrollElement}>
            <Grid container>
                <Grid item lg={2} md={2} sm={3} sx={{ mr: 2 }}>
                    <SelectMultiple
                        options={Object.values(hangarPlanLineOptions)}
                        onSelectedItem={(e: any) => { setSelectedHangars(e.target.value); localStorage.setItem("hangarFilters", JSON.stringify(e.target.value)) }}
                        selectedItemsString={selectedHangars}
                        width={200}
                        filter_by_text={"Filter by Hangars"}
                    />
                </Grid>
                <Grid item lg={2} md={2} sm={3} sx={{ mr: 2 }}>
                    <SelectMultiple
                        optionsObject={statusOptions}
                        onSelectedItem={(e: any) => { setSelectedStatusOptions(e.target.value); localStorage.setItem("statusFilters", JSON.stringify(e.target.value)) }}
                        selectedItems={selectedStatusOptions}
                        width={200}
                        filter_by_text={"Filter by status"}
                    />
                </Grid>
                <Button onClick={() => refresh()} variant="outlined" style={{ marginLeft: '8px', height: '20px', marginTop: '20px' }}>Refresh</Button>
            </Grid>
            {loading === true &&
                <Grid>
                    <br />
                    <LinearProgress />
                </Grid>
            }

            <MobiscrollCalendar calendarTasks={calendarTasks} resources={selectedHangars} onEventClick={(e: any) => onEventClicked(e)} />
            <Dialog
                fullScreen={true}
                show_options={false}
                strict={false}
                isSlide={true}
                max_width={'xl'}
                onClose={(status: any) => setDialogOpen(false)}
                title={""}
                visible={dialogOpen}
                context={
                    <Grid>
                        <Typography text={selectedEvent?.title} align='center' variant='h2' />
                        <Grid container justifyContent={'center'}>
                            <span style={{ display: 'inline' }}>
                                {/*  This code was outcommented in DDINT-3756, will be inroduced later
                                
                                {(selectedEvent?.mt_man_hours > 0 || selectedEvent?.mxi_man_hours > 0) && <span>Man hours: </span>}
                                {selectedEvent?.mt_man_hours > 0 && <span>MT: {formatFloatNumberToHoursMinString(selectedEvent?.mt_man_hours ?? -1)}</span>}
                                {selectedEvent?.mt_man_hours > 0 && selectedEvent?.mxi_man_hours > 0 && ', '}
                                {selectedEvent?.mxi_man_hours > 0 && <span>MXi: {formatFloatNumberToHoursMinString(selectedEvent?.mxi_man_hours ?? -1)}</span>} */}

                                {selectedEvent?.mt_man_hours > 0 && <span>Man hours: {formatFloatNumberToHoursMinString(selectedEvent?.mt_man_hours ?? -1)}</span>}

                            </span>
                        </Grid>
                        <Grid container justifyContent={'center'}>
                            <Typography text={`${selectedEvent?.start_date_text} - ${selectedEvent?.end_date_text}`} />
                        </Grid>
                        <Grid container justifyContent={'center'}>
                            <Typography text={selectedEvent?.wo_status} fontWeight='bold' margin={[1, 0, 0, 0]} />
                            <IconButton onClick={() => redirectToMxi(selectedEvent?.id)}><Link color="primary" /></IconButton>
                        </Grid>
                        <Grid container justifyContent={'center'} >
                            <Typography text={`ACTV (${tasksInWorkpackage.filter(s => s.status === "ACTV").length})`} align='center' variant='h5' textColor='#F5B53F' />
                            <Typography text={`In Work (${tasksInWorkpackage.filter(s => s.status === "IN WORK").length})`} align='center' variant='h5' textColor='#506E9F' margin={[0, 4, 0, 4]} />
                            <Typography text={`Pause (${tasksInWorkpackage.filter(s => s.status === "PAUSE").length})`} align='center' variant='h5' textColor='black' margin={[0, 4, 0, 0]} />
                            <Typography text={`Complete (${tasksInWorkpackage.filter(s => s.status === "COMPLETE").length})`} align='center' variant='h5' textColor='#497523' margin={[0, 4, 0, 0]} />
                            <Typography text={`Unassign (${tasksInWorkpackage.filter(s => s.unassigned === true).length})`} align='center' variant='h5' textColor='#FF0000' margin={[0, 4, 0, 0]} />
                        </Grid>
                        <Grid>
                            <Table
                                headers={["", "Task Name", "Start", "End", "Action", "Labour"]}
                                rows={tasksInWorkpackage}
                                generateBody={(row: MxiTask) => generateTableBody(row)}
                                defaultRowsPerPage={50}
                                pagination={true}
                            />
                        </Grid>
                    </Grid>
                }
            />
            {partDialogOpen === true && <Dialog
                fullScreen={false}
                show_options={false}
                strict={false}
                max_width={'lg'}
                onClose={(status: any) => setPartDialogOpen(false)}
                title={selectedTask!.task_name}
                visible={partDialogOpen}
                context={
                    <PartDetails taskBarcode={selectedTask!.barcode} />
                }
            />}
            {LabourDialogOpen === true && <Dialog
                fullScreen={false}
                show_options={false}
                strict={false}
                max_width={'lg'}
                onClose={(status: any) => setLabourDialogOpen(false)}
                title={selectedTask!.task_name}
                visible={LabourDialogOpen}
                context={
                    <Grid>
                        <EmployeeSearch place_holder='Search for employee' onSelectedEemployee={(id: number) => onSelectedEmployee(id)} />
                        <Collection edit={true} background={false} collectionList={selectedTask!.assigned_employees} title="Assigned Employees" nameProperty="employee_name" idProperty="employee_id" imageProperty="user_image" removeItem={(itemId: number) => onSelectedEmployee(itemId)} />
                    </Grid>
                }
            />}
        </Grid>
    );
}
export default HangarPlan;