import { useEffect, FunctionComponent, useState } from 'react';
import { Grid, TableRow, TableCell, IconButton, Divider, Paper } from '@mui/material';
import { Typography, Table, Input, Dialog } from '../../MaterialUiComponents';
import Box from '@mui/material/Box';
import { Collection } from '../../components';
import { FlightSchedule, LineWorkPackages } from '../../types/line';
import lineApi from '../../api/line';
import EmployeeSearch from '../../components/EmployeeSearch/EmployeeSearch';
import { User } from '../../types/user';
import { FlightTakeoff, Delete } from '@mui/icons-material';
import { redirectToMxi } from './../../config/mxiProvider';
import { Link } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    inAir: {
        backgroundColor: 'lightyellow',
    },
    inWork: {
        backgroundColor: 'lightblue',
    },
    closedReadyForWork: {
        backgroundColor: '#63AB62',
    },
    noPackagesOpen: {
        backgroundColor: 'orange',
    },
    AOG: {
        backgroundColor: 'orangered',
    },
    inAirCircle: {
        backgroundColor: 'lightyellow',
        height: "20px",
        width: "20px",
        borderRadius: "50px"
    },
    inWorkCircle: {
        backgroundColor: 'lightblue',
        height: "20px",
        width: "20px",
        borderRadius: "50px"
    },
    closedReadyForWorkCircle: {
        backgroundColor: '#63AB62',
        height: "20px",
        width: "20px",
        borderRadius: "50px"
    },
    noPackagesOpenCircle: {
        backgroundColor: 'orange',
        height: "20px",
        width: "20px",
        borderRadius: "50px"
    },
    AOGircle: {
        backgroundColor: 'orangered',
        height: "20px",
        width: "20px",
        borderRadius: "50px"
    },
    marginRight: {
        marginRight: '24px'
    },
    MuiTableCellRoot: {
        borderStyle: 'dashed',
        borderWidth: 2,
        borderRadius: 1,
    },
    leftBorder: {
        borderTop: "2px dashed gray",
        borderBottom: "2px dashed gray",
        borderLeft: "2px dashed gray",
    },
    rightBorder: {
        borderTop: "2px dashed gray",
        borderBottom: "2px dashed gray",
        borderRight: "2px dashed gray",
    },
    middleBorder: {
        borderTop: "2px dashed gray",
        borderBottom: "2px dashed gray",
    }
}));

interface LineHandoverProps {
    refresh: Function,
    flights: Array<FlightSchedule>,
    dataFetched: string
    sortByColumn: Function,
    ascending: boolean,
    orderByText: string,
    isLineSupervisorAdmin: Boolean
}

const LineFlightSchedule: FunctionComponent<LineHandoverProps> = ({ refresh, flights, dataFetched, sortByColumn, ascending, isLineSupervisorAdmin }) => {
    const classes = useStyles();
    const [remarksDialogOpen, setRemarksDialogOpen] = useState(false);
    const [remark, setRemark] = useState<string>("");
    const [mechanicsDialogOpen, setMechanicsDialogOpen] = useState(false);
    const [tailNumber, setTailNumber] = useState("");
    const [selectedEmployees, setSelectedEmployees] = useState<User[]>([]);
    const [paperWorkDialogOpen, setPapaerWorkDialogOpen] = useState(false);
    const [paperWorkPackages, setPaperWorkPackages] = useState(Array<LineWorkPackages>());

    useEffect(() => {
        //Using keyup instead of keydown to avoid multiple fetch calls
        const handleKeyUp = (event: KeyboardEvent) => R_key_Pressed(event);
        document.addEventListener('keyup', handleKeyUp, true);
        
        return () => {
            document.removeEventListener('keyup', handleKeyUp, true);
        }
    }, [remarksDialogOpen, mechanicsDialogOpen]); // Include state variables as dependencies
    
    const R_key_Pressed = (event: any) => {
        const keyCode = event.keyCode;
        if(remarksDialogOpen === false && mechanicsDialogOpen === false){
            if (keyCode === 82) {
                event.preventDefault();
                refresh();
            }
        }
    }
    const generateTablePaperworkBody = (row: LineWorkPackages, index: number) => {
        return (
            <TableRow hover>
                <TableCell>{index}</TableCell>
                <TableCell onClick={() => redirectToMxi(row.wo_barcode)}><IconButton><Link /></IconButton></TableCell>
                <TableCell>{row.wo_status}</TableCell>
                <TableCell>{row.wo_name}</TableCell>
                <TableCell>{row.wo_number}</TableCell>
                <TableCell>{row.start_date_text}</TableCell>
            </TableRow>
        )
    }
    const generateTableBody = (row: FlightSchedule, index: number) => {
        const dotted = row.wo_status_for_aircraft.status === "Incomplete paperwork";
        const onRemarksClicked = (index: number) => {
            if (isLineSupervisorAdmin) {

                setRemark(row.remarks);
                setTailNumber(row.tail_number);
                setRemarksDialogOpen(true);
            }
        }

        const onMechanicsClicked = (index: number) => {
            if (isLineSupervisorAdmin) {
                setTailNumber(row.tail_number);
                setSelectedEmployees(row.assigned_mechanics);
                setMechanicsDialogOpen(true);
            }
        }
        const onTailNumberClicked = (row: FlightSchedule) => {
            if (row.wo_status_for_aircraft.lineWorkpackages.length > 0) {
                setPapaerWorkDialogOpen(true);
                setTailNumber(row.tail_number);
                setPaperWorkPackages(row.wo_status_for_aircraft.lineWorkpackages);
            }
        }
        return (
            <TableRow
                className={(row.wo_status_for_aircraft.status === "In Air" || row.wo_status_for_aircraft.status === "Incomplete paperwork") ? classes.inAir :
                    row.wo_status_for_aircraft.status === "In Work" ? classes.inWork :
                        row.wo_status_for_aircraft.status === "Closed" ? classes.closedReadyForWork :
                            row.wo_status_for_aircraft.status === "AOG" ? classes.AOG : ""
                }
            >
                <TableCell className={dotted ? classes.leftBorder : ""}>{index}</TableCell>
                <TableCell className={dotted ? classes.middleBorder : ""} onClick={() => onTailNumberClicked(row)}>{row.tail_number}</TableCell>
                <TableCell className={dotted ? classes.middleBorder : ""}>
                    {row.current_location === "IN-AIR" ? <FlightTakeoff /> : row.current_location
                    }
                </TableCell>
                <TableCell className={dotted ? classes.middleBorder : ""}>{row.aircraft_stand_location}</TableCell>
                <TableCell className={dotted ? classes.middleBorder : ""}>{row.inbound_flight_number} {row.inbound_flight_number && " - "} {row.inbound_departure_airport_code}</TableCell>
                <TableCell className={dotted ? classes.middleBorder : ""}>{row.arrival_date_text}</TableCell>
                <TableCell className={dotted ? classes.middleBorder : ""}>{row.departure_date_text}</TableCell>
                <TableCell className={dotted ? classes.middleBorder : ""}>{row.outbound_flight_number} - {row.outbound_arrival_airport_code}</TableCell>
                <TableCell className={dotted ? classes.middleBorder : ""} onClick={() => onMechanicsClicked(index)}>
                    <Grid container direction="row" alignItems="center"  >
                        <Box display="flex" alignItems="center" justifyContent="center" >
                            <Grid>
                                {(row.assigned_mechanics.length > 0) &&
                                    row.assigned_mechanics.map((item, idx) => (
                                        <Grid key={idx}>
                                            <Typography text={item.abbreviation ? item.abbreviation : "?"} />
                                        </Grid>
                                    ))}
                            </Grid>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="center">
                            {
                                row.assigned_mechanics.length > 0 && isLineSupervisorAdmin && <IconButton onClick={(event: any) => handleRemoveEmployeesByTailNumber(event, row.tail_number)}><Delete /></IconButton>
                            }
                        </Box>
                    </Grid>
                </TableCell>
                <TableCell className={dotted ? classes.rightBorder : ""} onClick={() => onRemarksClicked(index)}>{row.remarks}</TableCell>
            </TableRow>
        )
    }

    const handleRemoveEmployeesByTailNumber = (event: any, tail_number: string) => {
        lineApi.RemoveAllLineMechanicsByTail(tail_number).then(res => {
            refresh();
        });
        //stops the onClick={() => onMechanicsClicked(index)} to open dialog from happening.
        event.stopPropagation();
    }

    const onSelectedEmployeeRow = (employee: User, type: string) => {
        if (type === "Assign" && employee !== null) {
            lineApi.AssignEMployeeFlightSchedule(employee, tailNumber).then(res => {
                if(res.status === true){
                    setSelectedEmployees([...selectedEmployees, employee]);
                }
            });
        }
    }

    const onCloseMechanicDialog = () => {
        setMechanicsDialogOpen(false);
        refresh();
    }

   const onRemoveEmployee = (employeeId: number) => {
        const employeeToRemove = selectedEmployees.findIndex(employee => employee.user_id === employeeId);
        const tempEmployees = [...selectedEmployees];
        tempEmployees.splice(employeeToRemove, 1);
        setSelectedEmployees(tempEmployees);
        lineApi.RemoveEmployeeFlightSchedule(tailNumber, employeeId).then(res => { });
    }

    const onUpdateRemarkToFlightSchedule = (status: boolean) => {
        if (status === true) {
            lineApi.UpdateFlightSchedule(remark, tailNumber).then(res => {
                setRemark("");
                refresh();
            });
        }
        setRemarksDialogOpen(false);
    }

    const onRemoveAllofLineShiftSupervisorEmployees = () => {
        lineApi.RemoveAllofLineShiftSupervisorEmployees().then(res => {
            refresh();
        });
    }
    return (
        <Grid>
            <Grid container direction="row" style={{ marginTop: "24px" }}>
                <Grid className={classes.marginRight}>
                    <Grid container direction="row"><div className={classes.inAirCircle} ></div> <Typography variant="h3" text={"Aircraft in air"} /></Grid>
                </Grid>
                <Grid className={classes.marginRight}>
                    <Grid container direction="row"><div className={classes.inWorkCircle} ></div><Typography variant="h3" text={"Package in work "} /></Grid>
                </Grid>
                <Grid className={classes.marginRight}>
                    <Grid container direction="row"><div className={classes.closedReadyForWorkCircle} ></div><Typography variant="h3" text={"All packages closed, ready for flight"} /></Grid>
                </Grid>
                <Grid className={classes.marginRight}>
                    <Grid container direction="row"><div className={classes.noPackagesOpenCircle} ></div><Typography variant="h3" text={"No packages open"} /></Grid>
                </Grid>
                <Grid className={classes.marginRight}>
                    <Grid container direction="row">
                        <Typography variant="h3" fontWeight='bold' text={"Dotted line"} />
                        <Typography variant="h3" text={"=Incomplete paperwork in mxi"} />
                    </Grid>
                </Grid>
                <Grid className={classes.marginRight}>
                    <Grid container direction="row"><div className={classes.AOGircle} ></div>
                        <Typography variant="h3" text={"AOG"} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid style={{ marginTop: "16px" }}><Typography align='center' variant="h1" text={`${dataFetched}`} /></Grid>
            <Grid style={{ marginTop: "16px" }}>
                <Typography align='center' variant="h2" text={'Press R to refresh'} />
            </Grid>
            {isLineSupervisorAdmin && <Grid container direction="row" style={{ marginTop: "16px" }} alignItems="center" justifyContent="center">
                <IconButton style={{ justifyContent: "center", alignItems: "center" }} onClick={() => onRemoveAllofLineShiftSupervisorEmployees()}><Delete /></IconButton>
                <Typography align='center' variant="h3" text={'Press to remove all of your employees'} />
            </Grid>}
            <br />
            <Table
                headers={[" ", "Tail", "Curr Airport", "Stand", "Arr Fl, nr.", "Arrival", "Departure", "Dep Fl. nr.", "Mechanics", "Remarks"]}
                rows={flights}
                generateBody={(row: any, index: number) => generateTableBody(row, index)}
                pagination={false}
                OnOrderByTitles={(columnName: string) => sortByColumn(columnName, flights, ascending)}
                skipIndexCol={true}
                displaySortAscending={ascending}
            />
            <Dialog
                visible={mechanicsDialogOpen}
                max_width={"md"}
                title={""}
                hideCloseButton
                context={
                    <Grid item>
                        <Grid item xs={12} alignContent={"center"} alignItems={"center"}>
                            <Paper elevation={3} style={{ padding: 6, margin: 4 }}>
                                <EmployeeSearch autoFocusOnInput searchByAbbreviation place_holder={"Search by abbreviation"} clearOnSelect fullUser={true} onSelectedEemployee={(employee: User) => onSelectedEmployeeRow(employee, "Assign")} />
                            </Paper>
                            <Divider style={{ marginTop: 26, marginBottom: 24 }} />
                            <Paper elevation={3} style={{ padding: 6, margin: 4 }}>
                                <EmployeeSearch place_holder={"Search by name"} clearOnSelect fullUser={true} onSelectedEemployee={(employee: User) => onSelectedEmployeeRow(employee, "Assign")} />
                            </Paper>
                            <Collection background={false} collectionList={selectedEmployees} title="Employees selected" idProperty="user_id" imageProperty="user_image" removeItem={(itemId: number) => onRemoveEmployee(itemId)} />
                        </Grid>
                    </Grid>
                }
                onClose={(status: any) => onCloseMechanicDialog()}
                strict={false}
                show_options={false}
            />
            <Dialog
                visible={remarksDialogOpen}
                max_width={"sm"}
                title={"Remarks"}
                context={
                    <Grid item>
                        <Input
                            multiline={true}
                            help_text={""}
                            label_text={"Remarks"}
                            value={remark}
                            onTextChange={(text: string) => setRemark(text)}
                            type="text"
                            width={100}
                        />
                    </Grid>
                }
                onClose={(status: any) => onUpdateRemarkToFlightSchedule(status)}
                strict={false}
                show_options={true}
            />
            <Dialog
                visible={paperWorkDialogOpen}
                max_width={"md"}
                title={`Paperwork for : ${tailNumber}`}
                context={
                    <Grid item>
                        <Table
                            headers={["Mxi", "Status", "Wo name", "Wo number", "start"]}
                            rows={paperWorkPackages}
                            generateBody={(row: any, index: number) => generateTablePaperworkBody(row, index)}
                            pagination={false}
                            skipIndexCol={false}
                        />
                    </Grid>
                }
                onClose={(status: any) => { setPapaerWorkDialogOpen(false); setPaperWorkPackages([]) }}
                strict={false}
                show_options={false}
            />
        </Grid>
    )
}


export default LineFlightSchedule;