import { settings } from './../config';
import { CalendarVacationCell, VacationRequest } from '../types/vacation';
import { getToken } from './../global/TokenValidator';

class Vacation {
    CreateVacationRequest = async (fromDate: Date, toDate: Date, mechanicReason: string, selectedLocation: string, halfDayChecked: boolean): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/Vacation/CreateVacationRequest`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                from_date: fromDate,
                to_date: toDate,
                mechanic_reason: mechanicReason,
                selected_location: selectedLocation,
                half_day_checked: halfDayChecked
            })
        }).then(x => {
            return x.json()
        })
    }
    GetAllVacationRequest = async (status: Array<string>, employeeId: number, fromDate: Date | null, toDate: Date | null,): Promise<{ status: Boolean, message: string, data: VacationRequest[] }> => {
        return fetch(`${settings.HOST}/Vacation/GetAllVacationRequest`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                from_date: fromDate,
                to_date: toDate,
                status: status,
                employee_id: employeeId
            })
        }).then(x => {
            return x.json()
        })
    }
    TimeControlUpdateVacationRequest = async (id: number, status: string, timeControlReason: string): Promise<{ status: Boolean, message: string, data: VacationRequest[] }> => {
        return fetch(`${settings.HOST}/Vacation/TimeControlUpdateVacationRequest`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                id: id,
                status: status,
                time_control_reason: timeControlReason,
                isTest: !settings.HOST.includes('https://api.icelandair.is/api/maintener-backend')? true : false
            })
        }).then(x => {
            return x.json()
        })
    }
    TimeControlUpdateApprovedVacationRequest = async (id: number, status: string, timeControlReason: string, fromDate: Date, toDate: Date): Promise<{ status: Boolean, message: string, data: VacationRequest[] }> => {
        return fetch(`${settings.HOST}/Vacation/TimeControlUpdateApprovedVacationRequest`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                id: id,
                status: status,
                time_control_reason: timeControlReason,
                from_date: fromDate,
                to_date: toDate,
                isTest: !settings.HOST.includes('https://api.icelandair.is/api/maintener-backend')? true : false
            })
        }).then(x => {
            return x.json()
        })
    }
    DeleteMyVacation = async (vacationRequestId: number, deletedReason: string): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/Vacation/DeleteMyVacation`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                vacation_request_id: vacationRequestId,
                deleted_reason: deletedReason
            })
        }).then(x => {
            return x.json()
        })
    }
    GetAllVacationEventsForCalendar = async (fixedDepartmentName: string): Promise<{ status: Boolean, data: CalendarVacationCell[] }> => {
        return fetch(`${settings.HOST}/Vacation/GetAllVacationEventsForCalendar/${fixedDepartmentName}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetVacationForTheDayByStartTime = async (startDate: Date, fixedDepartmentName: string): Promise<{ status: Boolean, data: VacationRequest[] }> => {
        return fetch(`${settings.HOST}/Vacation/GetVacationForTheDayByStartTime/${startDate}/${fixedDepartmentName}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetAllOfMyVacationRequests = async (): Promise<{ status: Boolean, data: VacationRequest[], message: string }> => {
        return fetch(`${settings.HOST}/Vacation/GetAllOfMyVacationRequests`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetAllVacationLocations = async (): Promise<{ status: Boolean, data: string[], message: string }> => {
        return fetch(`${settings.HOST}/Vacation/GetAllVacationLocations`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
}
const VacationApi = new Vacation();
export default VacationApi;