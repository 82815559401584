import { FunctionComponent } from 'react';
import { AppBar, Grid, IconButton } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux'
import { RootState } from '../../reducer';
import { Typography } from './../../MaterialUiComponents';
import { ProfileImg } from '../Images'; 

const useStyles = makeStyles(theme => ({
    appBar: {
        height: theme.spacing(6),
        boxShadow: 'none',
        borderBottom: '1px solid #EAEAEA',
        backgroundColor: theme.palette.common.white,
    },
    appBarTest: {
        height: theme.spacing(6),
        boxShadow: 'none',
        borderBottom: '1px solid #EAEAEA',
        backgroundColor: theme.palette.grey[800]
    },
    userPhoto: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        borderRadius: '50%',
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(0.2)
    },
    userBox: {
        position: 'fixed',
        right: '1%',
        display: 'flex',
        color: 'white'
    },
    menuButton: {
        marginLeft: theme.spacing(2),
    },
    links: {
        marginTop: '8px'
    }
}));

type HeaderProps = {
    onDrawerToggle: Function,
    isLandingPage: boolean,
}

const Header: FunctionComponent<HeaderProps> = ({ onDrawerToggle, isLandingPage }) => {
    const loc = window.location.href;
    let env = "prod";
    if (loc.includes("localhost")) { env = "localhost" }
    if (loc.includes("maintener-frontend")) { env = "test" }
    const classes = useStyles();
    const user = useSelector((state: RootState) => state.user);
  
    return (
        <AppBar className={env !== "prod" ? classes.appBarTest : classes.appBar} color="transparent" id="header" variant="outlined" elevation={0}>
            <Grid container>
                <Grid item xs={5}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => onDrawerToggle()}
                        edge="start"
                        className={classes.menuButton}
                    >
                        <Menu color={env !== "prod" ? "secondary" : "primary"} />
                    </IconButton>
                </Grid>
                {(env !== "prod" && !isLandingPage) &&
                    <Grid item >
                        <Typography textColor="white" align='center' variant='h1' text={env === "localhost" ? "Maintener Localhost" : "Maintener TEST"} margin={[0, 0, 0, 0]} />
                    </Grid>
                }
                {isLandingPage &&
                    <Grid className={classes.links}>
                        <Grid container justifyContent="space-between" spacing={9}>
                            <Grid item xs>
                                <a href="https://icelandair.atlassian.net/wiki/spaces/MCP/pages/140017892/Maintener">
                                    <Typography cursor="pointer" color={env !== "prod" ? "inherit" : "primary"} align='left' variant='body1' text={"Help"} />
                                </a>
                            </Grid>
                            <Grid item xs>
                                <a href="https://icelandair.atlassian.net/servicedesk/customer/portal/1/group/33">
                                    <Typography cursor="pointer" color={env !== "prod" ? "inherit" : "primary"} align='left' variant='body1' text={"Contact"} />
                                </a>
                            </Grid>
                            <Grid item xs>
                                <a href="https://icelandair.atlassian.net/wiki/spaces/MCP/pages/446791687/Release+Notes">
                                    <Typography cursor="pointer" color={env !== "prod" ? "inherit" : "primary"} align='left' variant='body1' text={"Releases"} />
                                </a>
                            </Grid>
                            <Grid item xs>
                                <a href="https://icelandair.atlassian.net/wiki/spaces/MCP/pages/448036874/FAQs">
                                    <Typography cursor="pointer" color={env !== "prod" ? "inherit" : "primary"} align='left' variant='body1' text={"FAQ"} />
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <Grid item className={classes.userBox}>
                   <ProfileImg userName={ user.me.user_name?.split('@')[0]} /> 
                    <Grid >
                        <Typography color={env !== "prod" ? "inherit" : "primary"} align='left' variant='body1' text={user.me.display_name} />
                        <Typography color={env !== "prod" ? "inherit" : 'textSecondary'} align='left' variant='body1' text={user.me.job_title} />
                    </Grid>
                </Grid>
            </Grid>
        </AppBar>
    );
};
export default Header
