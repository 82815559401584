/*export const settings = {  
    HOST: process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://maintener-prod.icelandair.is/taskplannerservice',
    WEBSOCKET_HOST: process.env.NODE_ENV === 'development' ? 'ws://localhost:8060' : 'wss://simple-ops-dev.dev.icelandairlabs.com/ws/',
    VERSION: 'v1'
};*/

const loc = window.location.href;

let path = "https://test-api.icelandair.is/api/maintener-backend"
if (loc.includes("localhost")) { path = "http://localhost:8080"}
if (loc.includes("maintener.icelandair.is")) { path = "https://api.icelandair.is/api/maintener-backend" }
if (loc.includes("maintener-frontend.stage")) { path = "https://api.icelandair.is/api/maintener-backend-training" }
if (loc.includes("maintener.icelandair.com")) { path = "https://api.icelandair.is/api/maintener-backend" }

export const settings = {
    HOST: path,
    KONG_KEY: process.env.REACT_APP_MAINTENER_BACKEND_API_KEY !== undefined ? process.env.REACT_APP_MAINTENER_BACKEND_API_KEY : '',
    //REACT_APP_MICRO_MAINTENER_SERVICE_API_KEY: process.env.REACT_APP_MICRO_MAINTENER_SERVICE_API_KEY !== undefined ? process.env.REACT_APP_MICRO_MAINTENER_SERVICE_API_KEY : '',
};

