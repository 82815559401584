import React, { FunctionComponent, useState } from 'react';
import { CalendarNav, Eventcalendar, MbscEventcalendarView, MbscResource, Button, CalendarToday } from '@mobiscroll/react';
import { Grid } from '@mui/material';
import { ShoppingCart, Person } from '@mui/icons-material';
import { Tooltip, Typography } from './../../MaterialUiComponents/';
import { makeStyles } from '@mui/styles';
import { formatFloatNumberToHoursMinString } from '../../utils/dateUtils';
import { EventInfo } from '../../types/mobiScroll';
import SoapIcon from '@mui/icons-material/Soap';


const useStyles = makeStyles(theme => ({
    calendarWrapper: {
        height: 'calc(100vh - 200px)'
    },
    addSpace: {
        marginRight: '3px'
    },
}));

type Props = {
    calendarTasks: EventInfo[],
    resources: Array<string>,
    onEventClick: Function
}

const MobiscrollCalendar: FunctionComponent<Props> = React.memo(({ calendarTasks, resources, onEventClick }) => {
    const classes = useStyles();
    const [currentDate, setCurrentDate] = useState<Date>(new Date());

    const view = React.useMemo<MbscEventcalendarView>(() => {
        return {
            timeline: {
                type: 'week',
                timeCellStep: 1440,
                weekNumbers: true,
                timeLabelStep: 1440,
            }
        };
    }, []);

    const calendarResources = Array<MbscResource>();
    resources.sort().map((row) => {
        calendarResources.push({
            id: row,
            name: row,
            color: '#e20000',
        })
    });

    const navigateToPrevPage = () => {
        const nextWeek = new Date(currentDate);
        nextWeek.setDate(nextWeek.getDate() - 7);
        setCurrentDate(nextWeek);
    };

    const navigateToNextPage = () => {
        const nextWeek = new Date(currentDate);
        nextWeek.setDate(nextWeek.getDate() + 7);
        setCurrentDate(nextWeek);
    };

    const customHeader = () => {
        return (
            <>
                <CalendarNav className="md-custom-header-nav" />
                <div className="md-custom-header-controls">
                    <Button onClick={navigateToPrevPage} icon="material-arrow-back" color='primary' variant="flat" className="md-custom-header-button"></Button>
                    <CalendarToday className="md-custom-header-today" />
                    <Button onClick={navigateToNextPage} icon="material-arrow-forward" color='primary' variant="flat" className="md-custom-header-button"></Button>
                </div>
                <div style={{ display: 'flex', marginLeft: '50px' }} >
                    <Typography text={"Complete"} align='center' variant='h6' fontWeight="bold" textColor='#497523' margin={[0, 4, 0, 0]} />
                    <Typography text={"ACTV"} align='center' variant='h6' fontWeight="bold" textColor='#f5b53f' margin={[0, 4, 0, 0]} />
                    <Typography text={"COMMIT"} align='center' variant='h6' fontWeight="bold" textColor='#e4b7b7' margin={[0, 4, 0, 0]} />
                    <Typography text={"IN WORK"} align='center' variant='h6' fontWeight="bold" textColor='#506e9f' margin={[0, 4, 0, 0]} />
                </div>
                <div style={{ display: 'flex'}}>
                    <SoapIcon  color={'primary'} />
                    <Typography text={"Cleaning tasks"} align='center' variant='h6' fontWeight="bold" margin={[0.5, 4, 0, 0]} />
                </div>
                <div style={{ display: 'flex'}}>
                    <ShoppingCart  color={'primary'} />
                    <Typography text={"Parts"} align='center' variant='h6' fontWeight="bold" margin={[0.5, 4, 0, 0]} />
                </div>
                <div style={{ display: 'flex'}}>
                    <ShoppingCart  color={'error'} />
                    <Typography text={"Parts amounts = Parts available"} align='center' variant='h6' fontWeight="bold" margin={[0.5, 4, 0, 0]} />
                </div>
            </>
        );
    };

    const myScheduleEvent = React.useCallback((event) => {
        const title = event.title;
        const packet = event.original;
        event.color = "#004776";
        return (
            <Grid >
                <Grid container >
                    <Grid item container id="icons">
                        {packet.parts_amounts > 0 && (
                            <Grid item>
                                <Tooltip title={`${packet.parts_amounts} parts`} type="primary" placement="top-start">
                                    <ShoppingCart style={{ cursor: 'pointer' }} color={packet.parts_amounts === packet.parts_available ? 'primary' : 'error'} />
                                </Tooltip>
                            </Grid>
                        )}
                        {packet.wo_includes_clean_task === 1 && (
                            <Grid item>
                                <Tooltip title={`Cleaning task`} type="primary" placement="top-start" >
                                    <SoapIcon style={{ cursor: 'pointer' }} color={'primary'} />
                                </Tooltip>
                            </Grid>
                        )}
                        {packet.assigned_employees.length > 0 && (
                            <Grid item>
                                <Tooltip title={`${packet.assigned_employees.length} employees`} type="primary" placement="top-start">
                                    <Person style={{ cursor: 'pointer' }} color="primary" />
                                </Tooltip>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item container>
                        <b className={classes.addSpace}>{title}</b>
                        {/*  This code was outcommented in DDINT-3756, will be inroduced later
                        
                        {(packet.mt_man_hours > 0 || packet.mxi_man_hours > 0) && <b className={classes.addSpace}> Man hours:</b>}
                        {packet.mt_man_hours > 0 &&
                            <b className={classes.addSpace}> MT: {formatFloatNumberToHoursMinString(packet.mt_man_hours)}
                                {packet.mt_man_hours > 0 && packet.mxi_man_hours > 0 && ', '}
                            </b>}
                        {packet.mxi_man_hours > 0 && <b> MXi: {formatFloatNumberToHoursMinString(packet.mxi_man_hours)} </b>} */}

                          { packet.mt_man_hours > 0 &&
                            <b className={classes.addSpace}> Man hours: {formatFloatNumberToHoursMinString(packet.mt_man_hours)}
                                {packet.mt_man_hours > 0 && packet.mxi_man_hours > 0}
                            </b>}

                    </Grid>
                    <Grid item>
                        <p>{packet.start_date_text} - {packet.end_date_text}</p>
                    </Grid>
                </Grid>
            </Grid>
        )
    }, []);

    return (
        <div className={classes.calendarWrapper}>
            <Eventcalendar
                theme="ios"
                themeVariant="light"
                view={view}
                selectedDate={currentDate}
                data={calendarTasks}
                resources={calendarResources}
                onEventClick={(e: any) => { onEventClick(e) }}
                renderScheduleEventContent={myScheduleEvent}
                fixedHeader={true}
                renderHeader={customHeader}
            />
        </div>
    );
})
export default React.memo(MobiscrollCalendar);