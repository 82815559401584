import { settings } from '../config';
import { Comments } from '../types/messages';
import { CertificateAircraftCategory, CertificateRating, CertificateData, Certificates,RatingsDifference } from '../types/certificates';
import { getToken } from './../global/TokenValidator';
class CertificateApi {
    GetAllCertififcateAircraftCategories = async (): Promise<{ status: Boolean, data: CertificateAircraftCategory[] }> => {
        return fetch(`${settings.HOST}/Certificate/GetAllCertififcateAircraftCategories`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),

            },
        }).then(x => {
            return x.json()
        })
    }
    GetCertificateForMe = async (): Promise<{ status: boolean, message: string, data: CertificateData }> => {
        return fetch(`${settings.HOST}/Certificate/GetCertificateForMe`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }

    GetCertificateByEmployeeId = async (id: number): Promise<{ status: boolean, message: string, data: CertificateData }> => {
        return fetch(`${settings.HOST}/Certificate/GetCertificateByEmployeeId/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }

    GetMessagesCertificateId = async (certificateId: number): Promise<{ status: boolean, data: Array<Comments> }> => {
        return fetch(`${settings.HOST}/Certificate/GetMessagesCertificateId/${certificateId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }
    GetAllCertificationRatings = async (): Promise<{ status: Boolean, data: CertificateRating[] }> => {
        return fetch(`${settings.HOST}/Certificate/GetAllCertificationRatings`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetAllValidCertificates = async (order_by_text: string, ascending: Boolean, type: string): Promise<{ status: Boolean, data: Certificates[] }> => {
        return fetch(`${settings.HOST}/Certificate/GetAllValidCertificates/${order_by_text}/${ascending}/${type}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetMechanicsValidCertificatesByEmployeeId = async (employeeId: number): Promise<{ status: Boolean, data: Certificates[] }> => {
        return fetch(`${settings.HOST}/Certificate/GetMechanicsValidCertificatesByEmployeeId/${employeeId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetCertificateById = async (id: number): Promise<{ status: Boolean, data: CertificateData }> => {
        return fetch(`${settings.HOST}/Certificate/GetCertificateById/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetAllCertificateWaitingApproval = async (): Promise<{ status: Boolean, data: Certificates[] }> => {
        return fetch(`${settings.HOST}/Certificate/GetAllCertificateWaitingApproval`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetCertificateStatus = async (mechanic_user_id: number): Promise<{ status: Boolean, data: Certificates }> => {
        return fetch(`${settings.HOST}/Certificate/GetCertificateStatus/${mechanic_user_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetCertificateHistoricalData = async (type: string, order_by_text: string, ascending: Boolean, aircraft: number[], rating: number[], onlyValid: boolean, employeeId: number, dateFrom: Date | null, dateTo: Date | null): Promise<{ status: Boolean, data: Certificates[], order_by_test: string, ascending: Boolean }> => {
        return fetch(`${settings.HOST}/Certificate/GetCertificateHistoricalData/${order_by_text}/${ascending}/${type}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    "mechanic_employee_id": employeeId,
                    "from_date": dateFrom,
                    "to_date": dateTo,
                    "aircraft_ids": aircraft,
                    "rating_ids": rating,
                    "onlyValid": onlyValid
                }
            )
        }).then(x => {
            return x.json()
        })
    }
    GetCertificateHistoricalDataToPdf = async (type: string, order_by_text: string, ascending: Boolean, aircraft: number[], rating: number[], onlyValid: boolean, employeeId: number, dateFrom: Date | null, dateTo: Date | null, isOnlySelectedFields: boolean): Promise<{ status: Boolean, data: Certificates[], order_by_test: string, ascending: Boolean }> => {
        return fetch(`${settings.HOST}/Certificate/GetCertificateHistoricalDataToPdf/${type}/${isOnlySelectedFields}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    "mechanic_employee_id": employeeId,
                    "from_date": dateFrom,
                    "to_date": dateTo,
                    "aircraft_ids": aircraft,
                    "rating_ids": rating,
                    "onlyValid": onlyValid
                }
            )
        }).then(x => {
            return x.json()
        })
    }
    ReturnDifferenceBetweenTwoCertificates = async (certificateId: number): Promise<{ status: Boolean, data: CertificateData, oldRatingIds:number[], newRatingIds:number[]}> => {
        return fetch(`${settings.HOST}/Certificate/ReturnDifferenceBetweenTwoCertificates/${certificateId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    CreateNewCertificateRating = async (ratingCategory: CertificateRating): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/Certificate/CreateNewCertificateRating`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(ratingCategory)
        }).then(x => {
            return x.json()
        })
    }
    CreateNewAircraftCategory = async (aircraftCategory: CertificateAircraftCategory): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/Certificate/CreateNewCertificateAircraftCategory`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(aircraftCategory)
        }).then(x => {
            return x.json()
        })
    }
    AddMessage = async (certificateId: number, message: string): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/Certificate/AddMessage`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                id: certificateId,
                message: message
            })
        }).then(x => {
            return x.json()
        })
    }
    CreateNewCertificateForEmployee = async (certificate: CertificateData): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/Certificate/CreateNewCertificateForEmployee`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(certificate)
        }).then(x => {
            return x.json()
        })
    }
    SendEmailApprovalMessage = async (employeeEmail: string, body: string, subject: string, approvalSentByEmail: string): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/Certificate/SendEmailApprovalMessage`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    "employee_email": employeeEmail,
                    "subject": subject,
                    "body": body,
                    "approvalSentByEmail": approvalSentByEmail,
                }
            )
        }).then(x => {
            return x.json()
        })
    }
    RemoveCertificateAircraftCategoryById = async (category: CertificateAircraftCategory): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/Certificate/RemoveCertificateAircraftCategoryById/${category.id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(category)
        }).then(x => {
            return x.json()
        })
    }
    RemoveCertificateRatings = async (rating: CertificateRating): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/Certificate/RemoveCertificateRatings/${rating.id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(rating)
        }).then(x => {
            return x.json()
        })
    }
    UpdateMxiRolesAndSkills = async (ratingsDifference: RatingsDifference): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/Certificate/UpdateMxiRolesAndSkills`, {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                oldRatings: ratingsDifference.oldRatings,
                newRatings: ratingsDifference.newRatings,
                userName: ratingsDifference.userName
            })
        }).then(x => {
            return x.json()
        })
    }
    QualityAssuranceAccept = async (certificateId: number, approverId: number): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/Certificate/QualityAssuranceAccept`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                id: certificateId,
                quality_assurance_employee_id: approverId,
            })
        }).then(x => {
            return x.json()
        })
    }
    QualityAssuranceReject = async (certificateId: number, approverId: number, declined_reason: string): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/Certificate/QualityAssuranceReject`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                id: certificateId,
                quality_assurance_employee_id: approverId,
                declined_reason: declined_reason
            })
        }).then(x => {
            return x.json()
        })
    }
}
const CertificatesApi = new CertificateApi();
export default CertificatesApi;