import { useEffect, useState } from 'react';
import { pdf, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { FunctionComponent } from 'react';
import { Button } from '@mui/material';
import { saveAs } from 'file-saver';
import IcelandairLogo from '../../assets/IcelandairNewLogo.png';

type PDFCreateProps = {
    buttonText?: string,
    dataLoaded: boolean,
    data: Array<any>,
    getExportData: Function,
    subRows: boolean,
    subRowColumnNames?: Array<string>
    mainRowColumnNames: Array<string>,
    mainRowColumnHeaders: Array<string>,
    subRowObject?: string,
}

const PDFCreate: FunctionComponent<PDFCreateProps> = (
    {
        buttonText = "Download PDF",
        dataLoaded,
        data = [],
        subRows,
        mainRowColumnNames,
        mainRowColumnHeaders,
        subRowColumnNames = [],
        getExportData,
        subRowObject = "",
    }) => {

    const styles = StyleSheet.create({
        page: {
            marginTop: '0px',
            marginBottom: '0px'
        },
        header: {
            fontSize: 18,
            textAlign: 'center',
            marginBottom: '5px',
            width: '80%',
            paddingLeft: '55px'
        },
        table: {
            display: "flex",
            width: "auto",
            borderStyle: "solid",
            borderWidth: 2,
            borderRightWidth: 3,
            borderBottomWidth: 1,
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row",
        },
        tableSubRow: {
            flexDirection: "row",
            backgroundColor: "#DCDCDC"
        },
        tableCell: {
            width: '150%',
            margin: "auto",
            marginTop: 5,
            marginBottom: 5,
            fontSize: 14,
            fontFamily: 'Helvetica-Bold',
        },
        tableSubCell: {
            margin: "auto",
            marginTop: 5,
            fontSize: 10,
        },
        tableHeader: {
            margin: "auto",
            marginTop: 5,
            fontSize: 14,
            fontWeight: 'bold'
        },
        employeeSection: {
            borderStyle: "solid",
            borderBottomWidth: 1,
        },
        logo: {
            width: '120px',
            right: '30px',
            bottom: '5px',
            zIndex: 1,
            position: 'relative'

        },
        container: {
            flexDirection: 'row',
            padding: '10px'
        }
    });

    // Controls for width of columns in rows and subrows, based on number of columns
    const columnWidth = 100 / mainRowColumnHeaders.length + "%";
    const subColumnWidth = 100 / subRowColumnNames.length + "%";
    const [loading, setLoading] = useState<boolean>(false);

    // Trigger if dataLoaded boolean props is changed to true, then we setLoading = true. This triggers a Please wait indicator on the button. 
    // Has to be done before pdfCreation starts because that freezes everything while running. The loading bool then triggers the pdf creation when true.
    //
    useEffect(() => {
        if (dataLoaded === true) {
            setLoading(true);
        }
        if (loading === true) {
            generatePDF();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataLoaded, loading]);

    // PDF Content is created here, looping through the object arrays
    const PDFContent = () => (
        <Document>
            <Page style={styles.page}>
                <View style={styles.container}><Text style={styles.header}>Icelandair - List of Certifying staff</Text>
                    <Image src={IcelandairLogo} style={styles.logo} /></View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        {mainRowColumnHeaders.map(name => (
                            <View style={{ width: columnWidth, borderStyle: 'solid', borderBottomWidth: 2 }}>
                                <Text style={styles.tableHeader}>{name}</Text>
                            </View>
                        ))}
                    </View>
                    {data.map(employee => (
                        <View wrap={false} style={styles.employeeSection}>
                            <View style={styles.tableRow}>
                                {mainRowColumnNames.map(item => (
                                    <View style={{ width: columnWidth }}>
                                        <Text style={styles.tableCell}>{employee[item]} ({employee['mechanic_employee_number']})</Text>
                                    </View>
                                ))}
                            </View>
                            {subRows === true &&
                                employee[subRowObject].map((certRight: any) => (
                                    <View style={styles.tableSubRow}>
                                        {subRowColumnNames.map(item => (

                                            <View style={{ width: subColumnWidth }}>
                                                <Text style={styles.tableSubCell}>{certRight[item]}</Text>
                                            </View>
                                        ))}
                                    </View>
                                ))}
                        </View>
                    ))}
                </View>
            </Page>
        </Document>
    );
    // Set the content as doc variable. Create blob with the content, that can take a while with much data. Then saved as document
    const generatePDF = async () => {
        const doc = <PDFContent />;
        const blob = await pdf(doc).toBlob();
        saveAs(blob, 'Certificates_Export.pdf');
        setLoading(false)
    }
    return (
        <Button variant="contained" color="primary" size="medium" onClick={() => getExportData()}>{loading === false ? buttonText : "Please wait..."}</Button>
    );
};
export default PDFCreate;
