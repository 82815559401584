import { FunctionComponent } from 'react';
import { Typography } from '../../MaterialUiComponents';
import { makeStyles } from '@mui/styles';
import { Card, CardContent, Grid } from '@mui/material';

const useStyles = makeStyles(theme => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '22px',
        borderTopLeftRadius: "2px",
        borderTopRightRadius: "2px"
    },
}));

type BayProps = {
    banner_color: string,
    bay: string,
}

const CardComponent: FunctionComponent<BayProps> = ({ bay, children, banner_color }) => {
    const classes = useStyles();
    return (
        <Card style={{ backgroundColor: "white" }} >
            <Grid style={{ backgroundColor: `${banner_color}` }} className={`${classes.header}`}>
                <Grid sx={{ paddingLeft: '0.5em', paddingRight: '0.5em' }}>
                    <Typography textColor={'white'} text={`${bay} `} align='center' variant='h5' margin={[0.1, 0, 0, 0]} fontWeight={'normal'} />
                </Grid>
            </Grid>
            <CardContent  >
                <Grid  >
                    {children}
                </Grid>
            </CardContent>
        </Card >
    )
}
export default CardComponent;