import { FunctionComponent, ReactFragment } from 'react';
import { Typography } from '@mui/material';

type TypographyUIProps = {
  text: string | number | undefined
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'caption' | 'button' | 'overline' | 'inherit',
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify',
  color?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error',
  paragraph?: true | false,
  display?: "inline" | "initial" | "block",
  fontWeight?: 'inherit' | 'initial' | 'revert' | 'unset' | 'bold' | 'bolder' | 'normal' | 'lighter',
  margin?: [number, number, number, number],
  padding?: [number, number, number, number],
  cursor?: 'pointer',
  textColor?: string | undefined,
  icon?: ReactFragment,
  textDecoration?: boolean,
  customFontSize?: number,
  alignSelf?: 'auto' | 'normal' | 'center' | 'start' | 'end',
}

const TypographyUI: FunctionComponent<TypographyUIProps> = ({ text, variant, align, color, paragraph, fontWeight, margin, cursor, textColor, icon, display = "block", textDecoration, customFontSize, padding, alignSelf }) => {
  const px_number = 8;
  const top = margin === undefined ? 0 : margin![0] * px_number;
  const right = margin === undefined ? 0 : margin![1] * px_number;
  const bottom = margin === undefined ? 0 : margin![2] * px_number;
  const left = margin === undefined ? 0 : margin![3] * px_number;

  const paddingTop = padding === undefined ? 0 : padding![0] * px_number;
  const paddingRight = padding === undefined ? 0 : padding![1] * px_number;
  const paddingBottom = padding === undefined ? 0 : padding![2] * px_number;
  const paddingLeft = padding === undefined ? 0 : padding![3] * px_number;

  return (
    <Typography
      style={{
        fontWeight: fontWeight,
        marginTop: `${top}px`,
        marginRight: `${right}px`,
        marginBottom: `${bottom}px`,
        marginLeft: `${left}px`,
        paddingTop: `${paddingTop}px`,
        paddingRight: `${paddingRight}px`,
        paddingBottom: `${paddingBottom}px`,
        paddingLeft: `${paddingLeft}px`,

        cursor: cursor === undefined ? 'default' : cursor,
        color: textColor === undefined ? '' : textColor,
        textDecoration: textDecoration === undefined ? '' : 'underline',
        fontSize: customFontSize === undefined ? '' : customFontSize,
      }}
      color={color}
      variant={variant}
      align={align}
      display={display}
      alignSelf={alignSelf}
      paragraph={paragraph}>
      {icon !== undefined && icon} {text}
    </Typography>
  );
};
export default TypographyUI